import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
import { PageContext } from "../store/context/page-context";
import { useTranslation } from "gatsby-plugin-react-i18next";
import useParamsForCustomizable from "../hooks/useParamsForCustomizable";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import LayoutLocalized from "../layouts/LayoutLocalized";
import Seo from "../components/Seo";
import CustomizableContainer from "../containers/Paywalls/Customizable/CustomizableContainer";
import "../components/customizable-template/index.scss";

const CustomizableTemplate = ({ pageContext }) => {
  const { isIframe, i18n } = pageContext;
  const pricingRef = useRef();
  const { t } = useTranslation();
  const { getOptions, getParams, isRendered } = useParamsForCustomizable();
  const [, setCurrentLocale] = useState(null);

  return isRendered ? (
    <PageContext.Provider
      value={{
        translate: t,
        pathname: i18n.originalPath,
        isIframe,
        category: "LANDING|Pricing",
        minimalHeader: true,
      }}
    >
      <LayoutLocalized bgHeader="#fff" setLocale={setCurrentLocale}>
        <Seo
          title="AMZScout: Accurate Amazon Product Finder & Research Tool"
          description="Find an Amazon FBA product and niche that will make you from $3,000/mo. Full info about demand and competitors. Trusted by 250,000 Amazon sellers. Click to learn more."
          page={i18n.path}
          manifest="browserconfig.xml"
          ogImg={OgImg}
        />
        <CustomizableContainer
          pricingRef={pricingRef}
          customClass="TeCuT-Pricing"
          language={t("code")}
          getParams={getParams}
          subTitlePro
          {...getOptions}
        />
      </LayoutLocalized>
    </PageContext.Provider>
  ) : null;
};
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "customizable" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default CustomizableTemplate;
